<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <!-- type -->
      <template #cell(type)="data">
        <span
          :style="`color: ${data.item.type === 'rejected' ? 'red' : 'green'}`"
        >
          {{ data.item.type }}
        </span>
      </template>
      <template #cell(schedule_approval)="data">
        <div
          v-if="data.item.type === 'pending'"
          style="padding: 3px 0px;"
        >
          <span
            class="cursor-pointer"
            @click="viewmodel(data.item.id)"
          >
            <img
              src="@/assets/images/icons/validity.png"
              width="35"
              height="35"
              class="text-center"
            >
          </span>
        </div>

      </template>
    </general-table>
  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'employee-schedule?pending_rejected=true',
      addType: 'Add Schedule',
      addComponentName: 'add-schedule',
      editComponent: 'edit-schedule',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'user.name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'from', label: 'From' },
        { key: 'to', label: 'To' },
        { key: 'status', label: 'Type' },
        { key: 'type', label: 'Status' },
        { key: 'schedule_approval', label: 'Need Approval' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'employee-schedule?pending_rejected=true?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    viewmodel(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to Approval this schedule!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approval',
        cancelButtonText: 'Disapproval',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`https://gulftic-system.fci.group/api/admin/employee-schedule/${id}/approve`)
            .then(() => {
              this.$swal('Approved!', 'Approve Successfully.', 'success')
              window.location.reload()
            })
            .finally(() => {
              this.loading = false
            })
        } else if (result.dismiss === 'cancel') {
          axios
            .put(`https://gulftic-system.fci.group/api/admin/employee-schedule/${id}/reject`)
            .then(() => {
              this.$swal('Disapproved!', 'Disapprove Successfully.', 'danger')
              window.location.reload()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

  <style></style>
